<template>
    <b-card>
        <Form
            ref="createGenre"
            :submit="submit"
        />
    </b-card>
</template>

<script>
import Form from "./partials/Form";
import { mapActions } from "vuex";

export default {
    name: "CreateGenre",

    components: {
        Form
    },

    methods: {
        ...mapActions({
            createGenre: 'genre/create'
        }),
        submit() {
            const refGenre = this.$refs.createGenre
            refGenre.errMessage = null

            const data = JSON.parse(JSON.stringify(refGenre.genre))
            data.meta_keywords = data.meta_keywords.toString()
            this.createGenre(data).then(() => {
                this.$router.push({ name: 'genres' })
            }).catch(response => {
                if (response.errors) {
                    refGenre.$refs.form.setErrors(response.errors)
                }
                refGenre.errMessage = response.message
            })
        }
    }
}
</script>
