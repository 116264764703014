<template>
    <validation-provider
        v-slot="{ errors }"
        :name="name"
        :rules="rules"
        :vid="vid"
    >
        <b-form-group
            :description="description"
            :invalid-feedback="errors[0]"
            :label="required ? name + ' * ' : name"
        >
            <b-form-tags
                :input-id="inputId"
                :placeholder="placeholder"
                :separator="separator"
                :size="size"
                :value="value"
                :state="errors.length > 0 ? false : null"
                :tag-pills="tagPills"
                :tag-validator="tagValidator"
                :tag-variant="tagVariant"
                @input="emitInput"
            >
                <template v-for="(_, slot) in $slots">
                    <template :slot="slot">
                        <slot :name="slot" />
                    </template>
                </template>
            </b-form-tags>
        </b-form-group>
    </validation-provider>
</template>

<script>
import { BFormGroup, BFormTags } from "bootstrap-vue";
import { DefaultTagProps } from "./defaultProps";

export default {
    name: "CustomTags",

    components: {
        BFormTags,
        BFormGroup
    },

    props: {
        ...DefaultTagProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        }
    }
}
</script>
