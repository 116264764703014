<template>
    <validation-observer
        ref="form"
        v-slot="{ invalid }"
        tag="div"
    >
        <b-overlay :show="status === 'LOADING'">
            <b-form @submit.prevent="submit">
                <b-row>
                    <b-col md="6">
                        <custom-input
                            v-model="genre.title"
                            :name="$t('title')"
                            required
                            rules="required|min:2"
                            vid="title"
                        />
                    </b-col>
                    <b-col md="6">
                        <custom-input
                            v-model="genre.slug"
                            :name="$t('slug')"
                            vid="slug"
                        />
                    </b-col>
                    <b-col cols="12">
                        <custom-input
                            v-model="genre.meta_title"
                            :name="$t('meta_title')"
                            vid="meta_title"
                        />
                    </b-col>
                    <b-col cols="12">
                        <custom-tags
                            v-model="genre.meta_keywords"
                            :name="$t('meta_keywords')"
                            vid="meta_keywords"
                        />
                    </b-col>
                    <b-col cols="12">
                        <custom-text-area
                            v-model="genre.meta_description"
                            :name="$t('meta_description')"
                            vid="meta_description"
                        />
                        <small v-if="genre.meta_description">
                            {{ $t('characters_count',{count: genre.meta_description.length} ) }}
                        </small>
                    </b-col>
                </b-row>
                <b-alert
                    :show="errMessage !== null"
                    class="p-2"
                    dismissible
                    variant="danger"
                >
                    {{ errMessage }}
                </b-alert>

                <b-button
                    :disabled="invalid"
                    class="mt-2"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('save') }}
                </b-button>

            </b-form>
        </b-overlay>
    </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { BAlert } from "bootstrap-vue";
import CustomTags from "@/layouts/components/form/CustomTags";

export default {
    name: "Form",

    components:{
        BAlert,
        CustomTags
    },

    props: {
        submit: {
            type: Function,
            required: true,
        },
        genre: {
            default() {
                return {
                    title: '',
                    slug: '',
                    meta_title: '',
                    meta_keywords: [],
                    meta_description: '',
                }
            },
            type: Object
        }
    },

    data() {
        return {
            errMessage: null,
        }
    },

    computed: {
        ...mapGetters('genre', [
            'status'
        ])
    }
}
</script>